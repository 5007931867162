
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'LoginIndex',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  // layout: 'minimal',
  data() {
    return {
      loggingIn: false,
      email: '',
      password: '',
    }
  },
  head() {
    const i18nHEAD = this.$nuxtI18nHead({ addSeoAttributes: true })

    i18nHEAD.link = i18nHEAD.link.map((link) => {
      if (!link.href.endsWith('/')) {
        link.href = link.href + '/'
      }
      return link
    })

    return {
      title: this.$t('Login') + ' | ATHLYZER',
      htmlAttrs: {
        ...i18nHEAD.htmlAttrs,
      },
      meta: [
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.$t('Login') + ' | ATHLYZER',
        },
        {
          hid: 'og-desc',
          property: 'og:description',
          content: this.$t(
            'Logge dich jetzt ein und ändere dein Abonnement oder Deine Zahlungsdaten. Start mit ATHLYZER ab 28€ p.M.',
          ),
        },
        ...i18nHEAD.meta,
      ],
      link: [...i18nHEAD.link],
    }
  },
  methods: {
    async login(e) {
      this.loggingIn = true
      try {
        const data = await this.$superlogin.login({
          username: this.email.toLowerCase(),
          password: this.password,
        })

        localStorage.setItem('loginEmail', this.email)
        localStorage.setItem('authToken', data.token)
        localStorage.setItem('authPassword', data.password)
        this.email = null
        this.password = null
        this.$refs.loginForm.reset()

        this.$store.dispatch('auth/retrieveCustomer')
        this.$store.commit('auth/setSession')
        this.$store.dispatch('auth/retrieveCustomerAccess')

        this.$gtag.event('login')

        this.$router.push({
          path: this.$route.query.redirect ? this.$route.query.redirect : '/',
        })
      } catch (e) {
        this.$sentry.captureException(e)
      }

      this.loggingIn = false
    },
  },
}
