import { render, staticRenderFns } from "./primary-button.vue?vue&type=template&id=a80cc816"
import script from "./primary-button.vue?vue&type=script&lang=js"
export * from "./primary-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasicsIconsRotatingCircle: require('/builds/athlyzer-devs/athlyzer-website/components/basics/icons/rotating-circle.vue').default})
