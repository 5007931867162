
export default {
  props: {
    id: { type: String, default: '' },
    type: { type: String, default: 'button' },
    loading: { type: Boolean, default: false },
    text: { type: String, default: '' },
    colorStyle: { type: String, default: '' },
    widthCss: { type: String, default: 'w-auto' },
    paddingCss: { type: String, default: 'py-2 px-3' },
    color: { type: String, default: '' },
    heightCss: { type: String, default: '' },
    justifyCss: { type: String, default: 'justify-center' },
    isDisabled: { type: Boolean, default: false },
  },
  computed: {
    buttonStyle() {
      let style = ''
      if (this.colorStyle === 'inverted') {
        style =
          'bg-white font-semibold leading-6 text-center text-sm text-primary-600 hover:bg-primary-50'
      } else if (this.colorStyle === 'secondary') {
        style =
          'text-white font-semibold leading-6 text-center text-sm bg-venice-blue-500 hover:bg-venice-blue-400 focus:ring-2 focus:ring-offset-2 focus:ring-venice-blue-500'
      } else if (this.colorStyle === 'outlined') {
        style =
          'rounded-md font-semibold leading-6 text-center text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300'
      } else {
        style =
          'text-white font-semibold leading-6 text-center text-sm bg-primary-500 hover:bg-primary-700 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
      }

      return style
    },
    loadingStateStyle() {
      let style = ''
      if (this.colorStyle === 'inverted') {
        style = 'text-primary-500'
      } else if (this.colorStyle === 'outlined') {
        style = 'text-primary-500'
      } else {
        style = 'text-white'
      }

      return style
    },
  },
}
